<template>
	<div>
		<div v-if="type=='block'" class="flex alcenter">
			<span class="order-t"  v-if="showT.d > 0" :class="size" :style="{background:bgColor,color:color}">{{showT.d}}</span><span v-if="showT.d > 0" class="ft12 ftw600 plr5">天</span>
			<span class="order-t"  :class="size" :style="{background:bgColor,color:color}">{{showT.h}}</span><span class="ft12 ftw600 plr5">:</span>
			<span class="order-t"  :class="size" :style="{background:bgColor,color:color}">{{showT.m}}</span><span class="ft12 ftw600 plr5">:</span>
			<span class="order-t"   :class="size" :style="{background:bgColor,color:color}">{{showT.s}}</span>
		</div>
		<div v-else :class="size">
			{{showT.d  > 0 ? showT.d + '天' : ''}}{{showT.h}}:{{showT.m}}:{{showT.s}}
		</div>
		
	</div>
</template>

<script>
	import  helper from '../../../common/helper.js';
	
	export default{
		props:{
			t:{
				type:Number,
				default:0,
			},
			type:{
				type:String,
				default:'block',
			},
			size:{
				type:String,
				default:''
			},
			bgColor:{
				type:String,
				default:'#2E2F33'
			},
			color:{
				type:String,
				default:'#ffffff'
			}
		},
		data(){
			return {
				myT:0,
				timer:null,
			}
		},
		computed:{
			showT(){
				let myT = this.myT;
				
				return helper.djsFull(myT);
			}
		},
		created(){
			this.myT = this.t;
			this.djs();
		},
		destroyed(){
			if(this.timer != null){
				clearInterval(this.timer);
			}
		},
		methods:{
			djs(){
				if(this.timer){
					clearInterval(this.timer);
				}	
				this.timer = setInterval(()=>{
					if(this.myT == 0){
						this.$emit('overTime');
						clearInterval(this.timer);
					}else{
						this.myT = this.myT - 1;
					}
				},1000);
			}
		}
	}
</script>

<style>
	.order-t{
		width:24px;
		height:20px;
		background:#FFFFFF;
		text-align: center;
		line-height: 20px;
		font-size:14px;
		font-weight: 500;
		color: #000000;
		border-radius: 4px;
	}
	.order-t.small{
		width: 20px;
		height: 20px;
		font-size: 12px;
		line-height: 20px;
	}
</style>